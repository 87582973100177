import { Checkbox, CustomSelectOption, Chip, Avatar, Card } from "@vkontakte/vkui";
import { ChipsSelect } from "@vkontakte/vkui/dist/components/ChipsSelect/ChipsSelect";
import { useEffect, useState } from 'react';
import { utils } from '../modules/utils';

export default function CitySettings({ settings, setSettings, setLoading }) {
    const [selectedCities, setSelectedCities] = useState([])
	const [cities, setCitie] = useState([])
    const [selectedRegions, setSelectedRegions] = useState([])
	const [regions, setRegions] = useState([])
	const [debounce, setDebounce] = useState(0)
	const [fetching, setFetching] = useState(false)
    const [fetchedRegions, setFetchedRegions] = useState({})

    const citiesChipsProps = {
        value: selectedCities,
        onChange: setSelectedCities,
        options: cities,
        placeholder: 'Выберите города',
        emptyText: 'Совсем ничего не найдено',
    };

    const regionChipsProps = {
        value: selectedRegions,
        onChange: setSelectedRegions,
        options: regions,
        placeholder: 'Выберите область',
        emptyText: 'Совсем ничего не найдено',
    };

    useEffect(() => {
        setSettings({...settings, cities: selectedCities})
    }, [selectedCities])

    useEffect(() => {
        const fetchCities = async () => {
            let regCities = []
            setLoading(true)
            for (let i = 0; i < selectedRegions.length; i++) {
                let regionCities = await utils.getRegionCities(selectedRegions[i].id)
                regCities.push(...regionCities);
            }
            setFetchedRegions(regCities)
            setLoading(false)
        }
        fetchCities()
    }, [selectedRegions])

    useEffect(() => {
        setSettings({...settings, fetchedRegions})
    }, [fetchedRegions])

    const searchCity = async (q) => {
		setFetching(true)
		if (debounce) clearTimeout(debounce)

		let timeout = setTimeout(async () => {
			let cityes = await utils.getCity(q)

			cityes.items.map(x => {
				x.value = x.id
				x.description = x.region ?? x.country
				x.label = x.title
			})

			setCitie(cityes.items);
			setFetching(false)
		}, 350)

		setDebounce(timeout)
	}

    const searchRegion = async (q) => {
		setFetching(true)
		if (debounce) clearTimeout(debounce)

		let timeout = setTimeout(async () => {
			let regions = await utils.getRegion(q)
			regions.items.map(x => {
				x.value = x.id
				x.label = x.title
			})

			setRegions(regions.items);
			setFetching(false)
		}, 350)

		setDebounce(timeout)
	}

    return <Card style={{ overflow: 'hidden', marginTop: '10px'}}>
        <Checkbox 
				description="Учитывать пользователей из определенного города"
				onChange={(e) => setSettings({...settings, city: e.target.checked ? 1 : 0})}
			>
				Проверить город
		</Checkbox>


        <div hidden={!settings.city}>
	    	<Checkbox 
	    		checked={settings.city === 2 ? true : false}
	    		description="Занимает больше времени, изучает профиль лучше"
	    		onChange={(e) => setSettings({...settings, city: e.target.checked ? 2 : 1})}
	    	>
	    	    Продвинутая проверка
	        </Checkbox>

            <ChipsSelect
                style={{ margin: '10px'}}
                fetching={fetching}
                {...citiesChipsProps}
                showSelected={false}
                renderChip={({ value, label, ...rest }) => <Chip value={value} {...rest}>{label}</Chip>}
                onInputChange={(e) => searchCity(e.target.value)}
                renderOption={({ option: { description }, ...otherProps }) => {
                    return (
                      <CustomSelectOption
                        description={description}
                        {...otherProps}
                      />
                    );
                }}
            />

            <ChipsSelect
                style={{ margin: '10px'}}
                fetching={fetching}
                {...regionChipsProps}
                showSelected={false}
                renderChip={({ value, label, ...rest }) => <Chip value={value} {...rest}>{label}</Chip>}
                onInputChange={(e) => searchRegion(e.target.value)}
                renderOption={({ ...otherProps }) => {
                    return (
                      <CustomSelectOption
                        {...otherProps}
                      />
                    );
                }}
            />

	    </div>
    </Card>
}