import "./Bonus.css"
import { Panel, PanelHeader, PanelHeaderBack, Alert } from '@vkontakte/vkui';
import { useEffect, useState } from 'react';
import SnackbarItem from "../../modules/SnackbarItem"
import request from '../../modules/getHttp'
import BonusBody from './BonusBody';

export default function BonusMenu({ id, go, group, setPopout, setModal }) {
    const [bonuses, setBonuses] = useState(null)
    const [snackbar, setSnackbar] = useState(null)

    const fetchData = async () => {
        let res = await request('/get_bonuses', "POST", { group_id: group.id})
        setBonuses(res);
    }

    const deleteBonus = async (bonus) => {
        if (!bonus) {
            try {
                let req = await request('/Zdelete_all_bonus', "POST", { group_id: group.id })
                if (req.error) {
                    setSnackbar(<SnackbarItem code="error" text={req.error.message ?? 'Ошибка сервера'} setSnackbar={setSnackbar}/>)
                } else {
                    setSnackbar(<SnackbarItem code="success" text="Все бонусы удалены!" setSnackbar={setSnackbar}/>)
                }
                fetchData()
            } catch (e) {
                setSnackbar(<SnackbarItem code="error" text={`Произошла ошибка.`} setSnackbar={setSnackbar}/>)
            }
        } else {
            try {
                let req = await request('/delete_bonus', "POST", { group_id: group.id, bonus_id: bonus._id })
                if (req.error) {
                    setSnackbar(<SnackbarItem code="error" text={req.error.message ?? 'Ошибка сервера'} setSnackbar={setSnackbar}/>)
                } else {
                    setSnackbar(<SnackbarItem code="success" text="Бонус удален!" setSnackbar={setSnackbar}/>)
                }
                fetchData()
            } catch (e) {
                setSnackbar(<SnackbarItem code="error" text={`Произошла ошибка.`} setSnackbar={setSnackbar}/>)
            }
        }
    }

    const deleteBonusAlert = async (bonus) => {
        let text = 'Вы собираетесь удалить все бонусы'
        let header = 'Удалить все'
        if (bonus) {
            text = `Вы собираетесь удалить ${bonus.points} бонусных баллов - Вариант ${bonus.answer.text}`
            header = 'Удалить бонус'
        }

        setPopout(<Alert
            actionsLayout="horizontal"
            onClose={() => setPopout(null)}
            header={header}
            text={text}
            actions={[
                {
                  title: 'Удалить',
                  mode: 'destructive',
                  autoClose: true,
                  action: () => deleteBonus(bonus)
                },
                {
                  title: 'Отмена',
                  autoClose: true,
                  mode: 'cancel',
                },
              ]}
        >
        </Alert>)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return <Panel id={id} style={{ height: '100vh' }}>
        <PanelHeader before={<PanelHeaderBack data-to="home-menu" onClick={go}/>}>
            Бонусы
        </PanelHeader>

        <BonusBody 
            group={group} 
            bonuses={bonuses} 
            deleteBonus={deleteBonusAlert} 
            setModal={setModal}
            setSnackbar={setSnackbar}
            fetchData={fetchData}
        />
        {snackbar}
    </Panel>
}