import './panels/Home.css'
import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import { View, ScreenSpinner, AdaptivityProvider, AppRoot, ConfigProvider, SplitLayout, SplitCol } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

import MainSettings from './panels/Settings/MainSettings';
import Auth from './panels/Auth';
import request from './panels/modules/getHttp';
import { utils } from './panels/modules/utils';
import GetToken from './panels/GetToken';
import Result from './panels/Calculate/Result';
import Init from './panels/Init/Init';
import HomeMenu from './panels/Home/HomeMenu';
import BonusMenu from './panels/Home/Bonuses/BonusMenu';
import Settings from './panels/Settings/Settins';

const App = () => {
	const [activePanel, setActivePanel] = useState('init');
	const [group, setGroup] = useState(null)
	const [activePolls, setActivePolls] = useState([])
	const [popout, setPopout] = useState(<ScreenSpinner size='large' />);
	const [settings, setSettings] = useState({ posts: [ {id: Math.random() * 1000000, value: ''}] })
	const [modal, setModal] = useState(null)

	const newGroup = async () => {
		let group_id = await utils.addToGroup()
		if (group_id) {
			let groupData = await bridge.send('VKWebAppGetGroupInfo', { group_id })
			setGroup(groupData);
			setActivePanel('home-menu')
		}
	}
	
	useEffect(() => {
		async function fetchData() {
			const user = await bridge.send('VKWebAppGetUserInfo');
			const key = (await bridge.send('VKWebAppStorageGet', {keys: ['key']})).keys?.[0]?.value

			if (!key) {
				setActivePanel('auth')
				setPopout(null)
			} else {
				const req = await request('/get_key', "POST", { key })
				if (req.error) {
					if (req.error.code === 422) {
						bridge.send('VKWebAppStorageSet', {key: 'key', value: ''})
						setPopout(null)
						return setActivePanel('auth')
					}
				}
			}

			setPopout(null)
			try {
				user.access_token = (await utils.getToken()).access_token
				if (!user.access_token) return setActivePanel('get_token')
				window.process.access_token = user.access_token
			} catch (e) {
				console.error(e);
				return setActivePanel('get_token')
			}
		}
		fetchData();
	}, []);

	const go = e => {
		if (typeof(e) === 'string') return setActivePanel(e)
		setActivePanel(e.currentTarget.dataset.to);
	};

	return (
		<ConfigProvider>
			<AdaptivityProvider>
				<AppRoot>
					<SplitLayout popout={popout} modal={modal}>
						<SplitCol>
							<View activePanel={activePanel}>
								<Init id="init" go={go} setGroup={setGroup} newGroup={newGroup}/>
								<HomeMenu id="home-menu" go={go} group={group}/>
								<BonusMenu id="bonuses" go={go} group={group}  setPopout={setPopout} setModal={setModal}/>
								<Settings id="settings" activePolls={activePolls} setActivePolls={setActivePolls} go={go} group={group}/>
								<MainSettings id='home' go={go} settings={settings} setSettings={setSettings} setModal={setModal} activePolls={activePolls}/>
								<Auth id='auth' go={go} setModal={setModal}/>
								<GetToken  id='get_token' go={go} />
								<Result id='result' go={go} settings={settings} setModal={setModal} setPopout={setPopout} group={group}/>
							</View>
						</SplitCol>
					</SplitLayout>
				</AppRoot>
			</AdaptivityProvider>
		</ConfigProvider>
	);
}

export default App;
