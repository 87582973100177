import "./HomeMenu.css"
import { Panel, PanelHeader, Avatar, Button, Card } from "@vkontakte/vkui";
import { Icon16StarCircleFillBlue } from '@vkontakte/icons';
import { Icon24CrownCircleFillVkDating } from '@vkontakte/icons';

export default function HomeMenu({ id, group, go }) {
    return <Panel id={id} style={{ height: '100vh'}}>
        <PanelHeader>Главное меню</PanelHeader>
		<div className="Menu">
			<Card style={{ padding: '30px 20px'}}>
        	<div className='HomeHeader'>
				<Avatar size={112} src={group.photo_200}/>
				<h1>{group.name}</h1>
				{group.admin ? <span>Вы вошли как руководитель</span> : <span>Вы вошли как пользователь</span>}
			</div>

			<div className="MainMenuButtons">
			{
				group.admin ? 
				<Button 
					size="l" 
					stretched
					data-to="bonuses"
					onClick={go}
					before={<Icon16StarCircleFillBlue width={24} height={24}/>} 
					style={{ marginTop: '10px' }}
				>
					Управление бонусами
				</Button>
			: null
			}

			<Button 
				size="l" 
				stretched
				data-to="settings"
				onClick={go}
				before={<Icon24CrownCircleFillVkDating />} 
				style={{ marginTop: '10px' }}
			>
				{group.admin ? `Провести конкурс` : 'Проверить опрос'}
			</Button>
			</div>
			</Card>
		</div>
    </Panel>
}