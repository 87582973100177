import { useEffect, useState } from "react"
import { ModalPage, ModalRoot, ModalPageHeader, PanelHeaderButton, FormItem, Input, Textarea, Button, Div, CustomSelect, CustomSelectOption, IconButton} from '@vkontakte/vkui'
import { Icon24Dismiss } from '@vkontakte/icons';
import { Icon28ChevronLeftCircle } from '@vkontakte/icons';
import { Icon28ChevronRightCircle } from '@vkontakte/icons';
import { Icon20FavoriteCircleFillGreen } from '@vkontakte/icons';
import SnackbarItem from '../../modules/SnackbarItem';
import request from "../../modules/getHttp";
import getWallPools from "../../modules/getWallPolls";

export default function BonusModal ({ setModal, group, setSnackbar, callback }) {
    const [points, setPoints] = useState('')
    const [comment, setComment] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [fetching, setFetching] = useState(false)
    const [polls, setPools] = useState([])
    const [activePool, setActivePoll] = useState(null)
    const [activeAnswer, setActiveAnswer] = useState(null)
    const [offset, setOffset] = useState(0)

    const offsetMode = (x) => {
        setPools([])
        setActivePoll(null)
        if (x < 0) {
            setOffset(offset - 100)
        } else {
            setOffset(offset + 100)
        }
    }

    const setBonus = async () => {
        setLoading(true)
        try {
            let res = await request('/new_bonus', "POST", {
                group_id: group.id,
                poll: activePool,
                answer: activeAnswer,
                points, comment
            })

            if (res.error) {
                setError(res.error.message ?? 'Произошла ошибка!')
            } else {
                setModal(null)
                setSnackbar(<SnackbarItem code="success" text={`Вариант ${res.answer.text} получил ${points} бонусов.`} setSnackbar={setSnackbar}/>)
                if (callback) callback()
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.error(e);
            setError('Произошла ошибка!')
        }
    }

    useEffect(() => {
        const fetchPolls = async () => {
            setFetching(true)
            let data = await getWallPools(offset, group.id)
            data.forEach(poll => {
				poll.value = poll.id
				poll.label = poll.question

                poll.answers.forEach(ans => {
                    ans.value = ans.id
                    ans.label = ans.text
                })
			})
            setFetching(false)
            setPools(data)
        }
        fetchPolls()
    }, [offset])

    return <ModalRoot activeModal='select'>
    <ModalPage 
        id='select' 
        dynamicContentHeight 
        onClose={() => setModal(null)}        
        header={
            <ModalPageHeader
               after={
                   <PanelHeaderButton onClick={() => setModal(null)}>
                        <Icon24Dismiss/>
                   </PanelHeaderButton>
                } >
                    Выдать бонус
            </ModalPageHeader>
    }>
        
    <Div style={{ padding: "0", paddingBottom: '30px'}}>
        <p className='Error' style={{ marginLeft: '20px'}}>{error}</p>

        <FormItem top="Выберите опрос" bottom={`Опросы в последних постах от ${offset} до ${offset + 100}`}>
            <CustomSelect
                options={polls}
                fetching={fetching}
                placeholder={'Выберите опрос'}
                onChange={(e) => {
                    let poll = polls.find(x => x.id === +e.target.value)
                    setActivePoll(poll)
                }}
                renderOption={({...otherProps }) => {
                    return (
                      <CustomSelectOption
                        {...otherProps}
                      />
                    );
                }}
            />
        </FormItem>
        <div className="BonusModalPages">
                <IconButton disabled={offset === 0}>
                    <Icon28ChevronLeftCircle onClick={() => offsetMode(-1)}/>
                </IconButton>
                <p>{offset}-{offset + 100}</p>
                <IconButton>
                    <Icon28ChevronRightCircle onClick={() => offsetMode(1)}/>
                </IconButton>
        </div>

        {
            activePool ? 
                <FormItem top="Выберите ответ">
                    <CustomSelect
                        options={activePool.answers}
                        placeholder={'Выберите вариант'}
                        onChange={(e) => {
                            let ans = activePool.answers.find(x => x.id === +e.target.value)
                            setActiveAnswer(ans)
                        }}
                        renderOption={({...otherProps }) => {
                            return (
                              <CustomSelectOption
                                {...otherProps}
                              />
                            );
                        }}
                    />
                </FormItem>
            : null
        }

        <FormItem top="Количество баллов">
            <Input type='number' placeholder="5" value={points}  onChange={(e) => setPoints(e.target.value)}/>
        </FormItem>

        <FormItem top="Комментарий">
            <Textarea  placeholder="Баллы выданы за ..." value={comment}  onChange={(e) => setComment(e.target.value)}/>
        </FormItem>

        <div className='ButtonsMenu'>
            <Button 
                before={<Icon20FavoriteCircleFillGreen />}
                onClick={setBonus} 
                loading={loading}>
                Выдать бонусные баллы
            </Button>
        </div>
    </Div>
    </ModalPage>
</ModalRoot>
}