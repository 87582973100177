import bridge from "@vkontakte/vk-bridge";
import { Button, CustomSelect, Panel, Spinner, CustomSelectOption, Avatar, FormItem, ButtonGroup} from "@vkontakte/vkui";
import { useEffect, useState } from "react";
import { utils } from "../modules/utils";
import { Icon24Users3Outline } from '@vkontakte/icons';
import { Icon28FavoriteAddOutline } from '@vkontakte/icons';

function GetGroup({ setGroup, newGroup }) {
    const [activeGroup, setActiveGroup] = useState(null)
    const [groupsList, setGroupsList] = useState([])
    const [debounce, setDebounce] = useState(0)
    const [fetching, setFetching] = useState(false)
    const [loading, setLoading] = useState(false)

    const customSearchFilter = (value, option) =>
    option.label.toLowerCase().includes(value.toLowerCase()) ||
    option.description.toLowerCase().includes(value.toLowerCase());

    const searchGroups = (q) => {
		setFetching(true)
		if (debounce) clearTimeout(debounce)
        
		let timeout = setTimeout(async () => {
			let groups = await utils.getGropus(q)

			groups.items.map(x => {
				x.value = x.id
				x.description = x.screen_name ?? x.id
				x.label = x.name
                x.src = x.photo_200
			})

			setGroupsList(groups.items);
			setFetching(false)
		}, 350)
		setDebounce(timeout)
	}

    useEffect(() => {
        searchGroups('')
    }, [])

    return <div className="MenuBody">
        <Icon24Users3Outline width={62} height={62}/>
        <h1>Выберите группу</h1>
        <FormItem
            style={{ width: "100%"}}
            top="Группа с конкурсом"
            htmlFor="groups"
            bottom="Поиск группы"
        >
        <CustomSelect 
            id="groups"
            options={groupsList}
            filterFn={customSearchFilter}
            placeholder={'Введите название группы'}
            searchable
            fetching={fetching}
            before={<Icon24Users3Outline />}
            onInputChange={(e) => {
                searchGroups(e.target.value)
            }}
            onChange={(e) => {
                let group = groupsList.find(x => x.id === +e.target.value)
                setActiveGroup(group)
            }}
            renderOption={({ option: { src, icon, description }, ...otherProps }) => {
                return (
                  <CustomSelectOption
                    before={icon ? <Avatar size={28}>{icon}</Avatar> : <Avatar size={28} src={src} />}
                    {...otherProps}
                    description={description}
                  />
                );
            }}
        />
        </ FormItem>
        <ButtonGroup>
            <Button 
		    	onClick={newGroup} 
		    	before={<Icon28FavoriteAddOutline  width={24} height={24}/>}
		    >
		    	Добавить в группу
		    </Button>
            <Button 
                loading={loading}
                disabled={!activeGroup} 
                onClick={() => {
                    setLoading(true)
                    setGroup(activeGroup)
                }}>
                    Далее
            </Button>
        </ButtonGroup>
    </div>
}

export default function Init({ id, go, setGroup, newGroup }) {
    const [body, setBody] = useState(<Spinner size="large"/>)

    const setNewGroup = async (group) => {
        const checkAdmin = await utils.getCommunityTocken(group.id)
        setGroup({...group, admin: process.env.NODE_ENV === 'development' ? true : checkAdmin})
        go('home-menu')
    }

    useEffect(() => {
        const init = async () => {
            const parms = await bridge.send('VKWebAppGetLaunchParams')
            if (!parms.vk_group_id) {
                setBody(<GetGroup setGroup={setNewGroup} newGroup={newGroup}/>)
            } else {
                let groupData = await bridge.send('VKWebAppGetGroupInfo', { group_id: parms.vk_group_id })
                const checkAdmin = await utils.getCommunityTocken(parms.vk_group_id)
                setGroup({...groupData, admin: process.env.NODE_ENV === 'development' ? true : checkAdmin})
				go('home-menu')
            }
        }

        init()
    }, [])

    return <Panel id={id} style={{ height: '100vh'}}>
        <div className="Menu">
            {body}
        </div>
    </Panel>
}