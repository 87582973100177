import { Group, Input, Panel, PanelHeader, Checkbox, Header, Select, Button, Footer, Link, SimpleCell, Avatar, CustomSelect, CustomSelectOption, PanelHeaderBack, Card} from '@vkontakte/vkui';
import { Icon16Stars } from '@vkontakte/icons';
import { useEffect, useState } from 'react';
import SnackbarItem from '../modules/SnackbarItem';
import AboutModal from '../modules/AboutModal';
import SubSettings from './SubSettings';
import CitySettings from './CitySettings';

const Home = ({ id, go, settings, setSettings, setModal, activePolls}) => {
	const [snackbar, setSnackbar] = useState(null)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setSettings({ posts: [ {id: Math.random() * 1000000, value: ''}] })
	}, [])

	
	const calculate = async () => {
		try {
			setSettings({...settings, result: activePolls})
			go('result')
		} catch (e) {
			return setSnackbar(<SnackbarItem code="error" text="Произошла ошибка получения постов!" setSnackbar={setSnackbar}/>)
		}
	}

	return <Panel id={id}>
		<PanelHeader before={<PanelHeaderBack onClick={go} data-to="settings"/>}
		>
			Проверка опросов
		</PanelHeader>
		<div className='MainMenu'>
			<Header mode='secondary'>Выбранные опросы</Header>
			{
				activePolls.map((poll) => {
					return <SimpleCell
						key={poll.id}
						style={{marginBottom: '10px'}}
						before={<Avatar size={48} src="/img/post.png" initials="ИБ" gradientColor="blue" />}
						subtitle={`Голоса: ${poll.votes}. Ответы: ${poll.answers.length}`}
					>
						{poll.question}
					</SimpleCell>
				})
			}

			<Group header={<Header mode="secondary">Настройки</Header>}>

				<Checkbox onChange={(e) => setSettings({...settings, deleteUsers: e.target.checked})}>
					Игнорировать удаленных пользователей
				</Checkbox>

				<Card style={{ overflow: 'hidden', marginTop: '10px' }}>
					<Checkbox 
						description="Не учитывать страницы с количеством подписчиков менее указаного"
						onChange={(e) => setSettings({...settings, friendsCount: e.target.checked})}
					>
						Учитывать друзей и подписчиков
					</Checkbox>
					<div hidden={!settings.friendsCount}>
						<Input 
							style={{ margin: '10px'}}
							placeholder='Количество друзей + подписчиков' 
							type='number'
							onChange={(e) => setSettings({...settings, friendsCountInput: e.target.value})}
						/>
					</div>
				</Card>


				<SubSettings settings={settings} setSettings={setSettings}/>

				<Card style={{ overflow: 'hidden', marginTop: '10px' }}>
					<Checkbox 
						onChange={(e) => setSettings({...settings, sex: e.target.checked, sexInput: settings.sexInput ?? 'Женщины'})}
					>
						Учитывать пол
					</Checkbox>
					<div hidden={!settings.sex}>
						<Select 
							style={{ margin: '10px'}}
                		    onChange={(e) => setSettings({...settings, sexInput: e.target.value})}
                		    placeholder={`Пол`}
							disabled={!settings.sex}
							defaultValue="Женщины"
                		    options={["Мужчины", "Женщины"].map(sex => ({
                		        label: sex,
                		        value: sex
                		    }))}
                		/>
					</div>
				</Card>
				
				<CitySettings settings={settings} setSettings={setSettings} setLoading={setLoading}/>

				<Card style={{ overflow: 'hidden', marginTop: '10px' }}>
					<Checkbox 
						description="Учитывать пользователей старше"
						onChange={(e) => setSettings({...settings, age: e.target.checked})}
					>
						Учитывать возраст
					</Checkbox>
					<div hidden={!settings.age}>
						<Input 
							style={{ margin: '10px'}}
							defaultValue={18}
							type='number' 
							disabled={!settings.age}
							onChange={(e) => setSettings({...settings, ageInput: e.target.value})}
						/>
					</div>
				</Card>
			</Group>

			<div className='ButtonsMenu' style={{ marginTop: '10px'}}>
				<Button 
					loading={loading}
					before={<Icon16Stars />}
					onClick={calculate}
				>
					Рассчитать результат
				</Button>
			</div>

			<Footer>
                Что-то пошло не так? Нашли ошибку? Напишите мне об этом: <Link href='https://vk.me/4vkinfo'  target='_blank'>vk.me/4vkinfo</Link>
            </Footer>

			<Footer onClick={() => {
				setModal(<AboutModal setModal={setModal}/>)
			}}>
				О приложении
			</Footer>
		</div>
		{snackbar}
	</Panel>
};

export default Home;
