import { Avatar, Button, ButtonGroup, Div, Group, Header, IconButton, RichCell, Spinner } from "@vkontakte/vkui";
import { Icon36GiftCirceFilled } from '@vkontakte/icons';
import { Icon16DeleteOutline } from '@vkontakte/icons';
import { useEffect } from "react";
import BonusModal from "./BonusModal";

export default function BonusBody({ group, bonuses, deleteBonus, setModal, setSnackbar, fetchData }) {
    const getBonus = () => {
		setModal(<BonusModal setModal={setModal} group={group} setSnackbar={setSnackbar} callback={fetchData}/>)
	}

    useEffect(() => {
    }, [bonuses])

    if (!bonuses) return <div style={{marginTop: '40px'}} className="MainMenu">
        <Spinner size="large"/>
    </div>

    if (!bonuses.length) return <div  className="Menu">
        <Icon36GiftCirceFilled width={64} height={64}/>
        <p>Вы еще не выдавали бонусы</p>
        <Button onClick={getBonus}>Выдать бонус</Button>
    </div>

return <div>
    <Group header={<Header mode="secondary">Управление</Header>}>
        <Div>
        <ButtonGroup mode="horizontal" gap="m" stretched>
            <Button 
                stretched 
                onClick={() => deleteBonus()} 
                appearance="negative"
            > 
                Очистить бонусы  
            </Button>
            <Button 
                stretched 
                onClick={getBonus}
            >
                Выдать бонус
            </Button>
        </ButtonGroup>
        </Div>
    </Group>


    <Header mode="secondary">Активные бонусы</Header>
    {
        bonuses.map(bonus => {
            return <RichCell
                key={bonus._id}
                text={`Опрос: ${bonus.poll.question}`}
                before={<Avatar src="/img/post.png"/>}
                caption={bonus.comment}
                afterCaption={`Баллы: ${bonus.points}`}
                after={<IconButton onClick={() => deleteBonus(bonus)}>
                    <Icon16DeleteOutline />
                </IconButton>}
            >
                {`Вариант: ${bonus.answer.text}`}
            </RichCell>
        })
    }
</div>
}