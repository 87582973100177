import bridge from '@vkontakte/vk-bridge'

const regionCities = new Map()

export const utils = {
    addToGroup: async () => {
		let data = await bridge.send('VKWebAppAddToCommunity')
		return data.group_id
	},

    getCommunityTocken: async (id) => {
		return new Promise(async (res) => {
            const key = (await bridge.send('VKWebAppStorageGet', {keys: [`${id}`]})).keys?.[0]?.value
            window.process.GROUP_TOKEN = key
            if (key) return res(true)
            if (window.process.GROUP_TOKEN) return res(true)
			bridge.send("VKWebAppGetCommunityToken", {
				app_id: window.process.APP_ID,
				group_id: id,
				scope: 'manage'
			}).then((data) => { 
			 	if (data.access_token) {
                    bridge.send('VKWebAppStorageSet', {key: `${id}`, value: data.access_token})
			 	  	window.process.GROUP_TOKEN = data.access_token
				   	res(true)
			 	}
			}).catch((error) => {
                res(false)
			});
		})
	},
    
    getToken: async function () {
        const access_token = await bridge.send('VKWebAppGetAuthToken', {
            app_id: window.process.APP_ID,
            scope: 'wall',
        })

        return access_token
    },

    getPosts: async (posts) => {
        let ids = []

        posts.map(post => {
            if (!post.value) return
            let str = post.value.split('wall');
            ids.push(str[1])
        })

        try {
            let data = await bridge.send('VKWebAppCallAPIMethod', {
                method: 'wall.getById',
                params: {
                    posts: ids.toString(),
                    v: '5.131',
                    access_token: window.process.access_token
            }})
    
            return data.response
        } catch (e) {
            console.error(e);
            throw new Error('Произошла ошибка')
        }
    },

    getPool: async (polls, inter, callback) => {
        const getUsers = (pool) => {
            const getAnswerUsers = async (answer) => {
                return new Promise((res, rej) => {
                    let offset = 0
                    let users = []
                    
                    const interval = setInterval(async () => {
                        let data = await bridge.send('VKWebAppCallAPIMethod', {
                            method: 'polls.getVoters',
                            params: {
                                owner_id: pool.owner_id,
                                poll_id: pool.id,
                                answer_ids: answer.id,
                                count: 1000,
                                offset,
                                v: '5.131',
                                access_token: window.process.access_token
                            }}
                        )
                        offset += 1000
                        users.push(...data.response[0].users.items)
                        if (!data.response[0].users.items.length || offset > data.response[0].users.count) {
                            clearInterval(interval)
                            callback(users.length)
                            res(users)
                        }
                    }, 300)

                    inter.current = interval
                    return users
                })
            }


            return new Promise(async (res, rej) => {
                let users = {}
                let answers = {}
                let count = 0

                for (let i = 0; i < pool.answers.length; i++) {
                    let answerUsers = await getAnswerUsers(pool.answers[i])
                    answers[pool.answers[i].id] = pool.answers[i].text
                    users[pool.answers[i].id] = answerUsers
                    count += answerUsers.length
                }
                res({users, answers , count})
            })
        }

        return new Promise(async (res, rej) => {
            let users = {answers: [], users: {count: 0}}

            for (let i = 0; i < polls.length; i++) {
                let poolUsers = await getUsers(polls[i])
                users.users.count += poolUsers.count
                users.users = {...users.users, ...poolUsers.users}
                users.answers = {...users.answers, ...poolUsers.answers}
            }

            res(users)
        })
    },

    usersGet: async (userIds, inter, callback) => {
        return new Promise((res, rej) => {
            let offset = 0
            let users = []
            let fields = ['sex', 'bdate', 'followers_count', 'photo_200', 'timezone', 'city']

            let interval = setInterval(async () => {
                
                let ids = []
                for (let i = offset; i < offset + 100; i++) {
                    if (userIds[i]) {
                        ids.push(userIds[i])
                    }
                }

                let data = await bridge.send('VKWebAppCallAPIMethod', {
                    method: 'users.get',
                    params: {
                        user_ids: ids.toString(),
                        count: 100,
                        fields: fields.toString(),
                        offset,
                        v: '5.131',
                        access_token: window.process.access_token
                    }}
                )

                offset += 100

                callback(data.response.length)

                if (data.response) {
                    users.push(...data.response)
                }
                
                if (offset > userIds.length) {
                    res(users)
                    clearInterval(interval)
                }

            }, 500)


            inter.current = interval
        })
    },

    getInfo: async (settings, inter, users) => {
        const getGroupSubscribe = async (url) => {
            return new Promise((res, rej) => {
                let offset = 0

                let interval = setInterval(async () => {
                    let ids = []

                    for (let i = offset; i < offset + 500; i++) {
                        if (users[i]) {
                            ids.push(users[i].id)
                        }
                    }
                    
                    let data = await bridge.send('VKWebAppCallAPIMethod', {
                        method: 'groups.isMember',
                        params: {
                            group_id: url,
                            user_ids: ids.toString(),
                            count: 500,
                            v: '5.131',
                            access_token: window.process.access_token
                        }}
                    )

                    console.log(data.response.length, ids.length);
                    
                    data.response.map(us => {
                        if (!us.member) {
                            let user = users.find(x => x.id === us.user_id)
                            if (!user.ban) user.ban = []
                            user.ban.push('subscribe')
                        } 
                    })


                    offset += 500

                    if (offset >= users.length) {
                        res(users)
                        clearInterval(interval)
                    }
                }, 500)

                inter.current = interval
            })
        }


        return new Promise(async (res, rej) => {
            if (settings.groupSubscribeAll) {
                for (let i = 0; i < settings?.groups?.length; i++) {
                    await getGroupSubscribe(settings?.groups[i]?.screen_name)
                }
            }
            res(users)
        })
    },

    getCitySubscribers: async (users, inter, callback) => {
        return new Promise((res, rej) => {
            let offset = 0

            const loop = async () => {
                let stepUsers = []

                for (let i = offset; i < offset + 25; i ++) {
                    if (users[i]) stepUsers.push(users[i].id)
                }

                let code = `
                var users = [${stepUsers}];
                var result = [];
                var i = 0;
        
                while (i < users.length) {
                    result.push({"user_id": users[i],  "result": API.users.getSubscriptions({ user_id: users[i] })});
                    i = i + 1;
                }
        
                return result;
                `

                let data = await bridge.send('VKWebAppCallAPIMethod', {
                    method: 'execute',
                    params: {
                        code: code,
                        v: '5.131',
                        access_token: window.process.access_token
                    }}
                )

                offset += 25
                data.response.map(result => {
                    let user = users.find(x => x.id === result.user_id)
                    user.subscribers = result.result
                    callback(result?.result?.groups?.items?.length || 0)
                })
                if (offset > users.length) {
                    res(users)
                    clearTimeout(inter.current)
                } else {
                    if (!inter.current) return
                    inter.current = setTimeout(() => loop(), 500);
                }
            }


            inter.current = setTimeout(() => loop(), 500);
        })
    },

    getCityGroups: async (groups, cities, inter, settings, callback) => {
        return new Promise((res, rej) => {
            let offset = 0
            let groupsResult = []

            const loop = async () => {
                if (offset > groups.length) return 
                let stepGroups = []
                offset += 5000

                for (let i = offset - 5000; i < offset - 5000 + (groups.length > 5000 ? 5000 : groups.length); i ++) {
                    if (groups[i]) {
                        let gr = stepGroups.find(x => x.length < 500)
                        if (!gr) {
                            stepGroups.push([groups[i]])
                        } else {
                            gr.push(groups[i])
                        }
                    }
                }

                let code = `
                var groups = [${stepGroups.map(x => `[${x}]`)}];
                var result = [];
                var i = 0;
        
                while (i < groups.length) {
                    result.push(API.groups.getById({ group_ids: groups[i]}));
                    i = i + 1;
                }
        
                return result;
                `

                let data = await bridge.send('VKWebAppCallAPIMethod', {
                    method: 'execute',
                    params: {
                        code: code,
                        v: '5.131',
                        access_token: window.process.access_token
                    }}
                )

                data.response.map(groups => {
                    let citiesString = cities.join('|').replace(/\(/g, "")
                    groupsResult.push(...groups.filter(group => group.name.toLowerCase().match(new RegExp(citiesString)) !== null))
                })

                callback(5000)

                if (offset > groups.length) {
                    res(groupsResult)
                    clearTimeout(inter.current)
                } else {
                    if (!inter.current) return
                    inter.current = setTimeout(() => loop(), 500);
                }
            }

            inter.current = setTimeout(() => loop(), 500);
        });
    },

    getCityFriends: async (settings, usersResult, users, inter, callback) => {
        return new Promise((res, rej) => {
            let offset = 0

            const loop = async () => {
                if (offset > users.length - 1) return 
                offset += 1
                let ids = []


                for (let i = offset - 1; i < offset; i++) {
                    if (users[i]) ids.push(users[i])
                }

                let code = `
                var users = [${ids}];
                var result = [];
                var i = 0;
        
                while (i < users.length) {
                    result.push({"user_id": users[i],  "result": API.friends.get({ user_id: users[i], count: 1000, fields: "city" })});
                    i = i + 1;
                }
        
                return result;
                `

                let data = {response: {}}
                try {
                    data = await bridge.send('VKWebAppCallAPIMethod', {
                        method: 'friends.get',
                        params: {
                            user_id: users[offset - 1],
                            fields: 'city',
                            v: '5.131',
                            access_token: window.process.access_token
                        }
                    })
                } catch (e) {
                    console.error(e);
                }


                //let data = await bridge.send('VKWebAppCallAPIMethod', {
                //    method: 'execute',
                //    params: {
                //        code: code,
                //        v: '5.131',
                //        access_token: window.process.access_token
                //    }}
                //)

                if (data?.response?.count) callback(data.response.count)
                let user = usersResult.find(x => x.id === users[offset - 1])
                user.friends = data?.response?.items?.filter(x => x?.city?.id === settings.cityInput.id).length ?? 0

                //data.response.map(result => {
                //    let user = usersResult.find(x => x.id === result.user_id)
                //    user.friends = result?.result?.items?.filter(x => x?.city?.id === settings.cityInput.id).length ?? 0
                //    if (result.result.count) callback(result.result.count)
                //})

                if (offset > users.length - 1) {
                    res(200)
                    clearTimeout(inter.current)
                } else {
                    if (!inter.current) return
                    inter.current = setTimeout(() => loop(), 500);
                }
            }

            inter.current = setTimeout(() => loop(), 500);
        })
    },

    getCity: async (q) => {
        let data = await bridge.send('VKWebAppCallAPIMethod', {
            method: 'database.getCities',
            params: {
                q: q,
                v: '5.131',
                access_token: window.process.access_token
            }}
        )

        return data.response
    },

    getRegion: async (q) => {
        let data = await bridge.send('VKWebAppCallAPIMethod', {
            method: 'database.getRegions',
            params: {
                country_id: 1,
                q: q,
                v: '5.131',
                access_token: window.process.access_token
            }}
        )

        return data.response
    },

    getRegionCities: async (region, timeout) => {
        let cities = regionCities.get(region)
        if (cities) return cities

        return new Promise(res => {
            cities = []
            let offset = 0

            const fetchCities = async () => {
                let data = await bridge.send('VKWebAppCallAPIMethod', {
                    method: 'database.getCities',
                    params: {
                        country_id: 1,
                        region_id: region,
                        need_all: 0,
                        count: 1000,
                        offset,
                        v: '5.131',
                        access_token: window.process.access_token
                    }}
                )
                cities.push(...data.response.items)
                if (data.response.items < 1000) {
                    regionCities.set(region, cities)
                    return res(cities)
                } else {
                    offset += 1000
                    timeout = setTimeout(() => {
                        fetchCities()
                    }, 400)
                }
            }
            fetchCities()
        })
    },

    getGropus: async (q) => {
        let data = await bridge.send('VKWebAppCallAPIMethod', {
            method: 'groups.search',
            params: {
                q,
                v: '5.131',
                access_token: window.process.access_token
            }}
        )

        return data.response
    },

    download: (filename, text) => {
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);
    
      element.style.display = 'none';
      document.body.appendChild(element);
    
      element.click();
    
      document.body.removeChild(element);
    }
}