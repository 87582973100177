import { ModalPage, ModalRoot, ModalPageHeader, PanelHeaderButton, Link, SimpleCell, InfoRow, Footer, Group, Separator, Avatar } from '@vkontakte/vkui'
import { Icon24Dismiss } from '@vkontakte/icons';
import { Icon56GhostOutline } from '@vkontakte/icons';

const ban = {
    'sex': 'Пол',
    "ban": 'Заблокирован',
    'frends': 'Друзья/Подписчики',
    "age": "Возраст",
    "subscribe": 'Подписка',
    "city": 'Город'
}

const ResultModal = ({ answer, setModal }) => {
    return <ModalRoot activeModal='select'>
                <ModalPage id='select' dynamicContentHeight onClose={() => setModal(null)}        
                    header={
                        <ModalPageHeader
                           after={
                               <PanelHeaderButton onClick={() => setModal(null)}>
                                    <Icon24Dismiss/>
                               </PanelHeaderButton>
                            } >
                                Статистика
                        </ModalPageHeader>
                }>
                    {
                        !answer.users.filter(x => x.ban).length ?
                        <Group>
                            <Footer>Не найдено пользователей которые не проходят по критериям</Footer>
                            <Icon56GhostOutline style={{ margin: '0 auto'}}/>
                            <Footer></Footer>
                        </Group>
                        :
                        <Footer>Ниже отображены пользователи которые не прошли проверку, а так же причина ({answer.users.filter(x => x.ban).length} не прошли по критериям)</Footer>
                    }
                {
                    answer.users.filter(x => x.ban).map(user => {
                        return <Link href={`https://vk.com/id${user.id}`} target='_blank' key={user.id} >
                            <SimpleCell 
                                multiline 
                                before={<Avatar size={48} src={user?.photo_200} initials="ИБ" gradientColor="blue" />}
                            >
                                <InfoRow header={`${user?.first_name} ${user?.last_name} (id${user.id})`}>
                                        {`${user.ban.map(x => ` ${ban[x]}`)}`}
                                </InfoRow>
                            </SimpleCell>
                        </Link>
                    })
                }
                <Footer>-</Footer>
        </ModalPage>
    </ModalRoot>
}

export default ResultModal
