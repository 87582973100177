import { Panel, PanelHeader, Button, Input, Footer, Link, IconButton } from '@vkontakte/vkui';
import { useState } from 'react';
import { Icon28StoryQuestionCircleFillViolet } from '@vkontakte/icons';
import SnackbarItem from './modules/SnackbarItem';
import request from './modules/getHttp';
import bridge from '@vkontakte/vk-bridge';
import AboutModal from './modules/AboutModal';

const Auth = ({ id, go, setModal }) => {
    const [key, setKey] = useState('')
    const [snackbar, setSnackbar] = useState(null)

    const auth = async () => {
        try {
            let req = await request('/set_key', 'POST', { key })
            if (req.error) {
                setSnackbar(<SnackbarItem code="error" text={req.error.message || "Произошла ошибка!"} setSnackbar={setSnackbar}/>)
            } else {
                go('init')
                bridge.send('VKWebAppStorageSet', {key: 'key', value: req.key.value})
            }
        } catch (e) {
            console.error(e);
            setSnackbar(<SnackbarItem code="error" text="Произошла неизвестная ошибка!" setSnackbar={setSnackbar}/>)
        }
    }

	return <Panel id={id}>
		<PanelHeader before={<IconButton onClick={() => {
			setModal(<AboutModal setModal={setModal}/>)
		}}>
			<Icon28StoryQuestionCircleFillViolet width={24} height={24}/>
		</IconButton>}>
            Авторизация
        </PanelHeader>
        <div className='MainMenu'>
            <div className='AuthMenu'>

                <p className='AuthMenuHeader'>
                    К сожалению, для вас приложение не активировано. Введите ключ доступа, чтобы активировать приложение
                </p>

                <div className='AuthMenuInput'>
                    <Input placeholder='gB5w0Td-m1KpwHK-2cnJGQc' onChange={(e) => setKey(e.target.value)}/>
                </div>

                <Button onClick={auth} size='l' style={{ marginTop: '20px' }}>Активировать</Button>
                
                <Footer>
                    Получить ключ у бота: <Link href='https://vk.me/4vkinfo' target='_blank'>vk.me/4vkinfo</Link>
                </Footer>
            </div>
        </div>
        {snackbar}
	</Panel>
};

export default Auth;