import bridge from '@vkontakte/vk-bridge'

export default async function getWallPools(offset, id) {
    let data = await bridge.send('VKWebAppCallAPIMethod', {
        method: 'wall.get',
        params: {
            count: 100,
            offset,
            owner_id: -id,
            v: '5.131',
            access_token: window.process.access_token
        }
    }) 

    let answers = []
    data.response.items.forEach(post => {
        let answer = post.attachments.find(x => x.type === 'poll')
        if (answer) answers.push(answer.poll)
    });

    return answers
}