import { Panel, PanelHeader, PanelHeaderBack, Spinner, SimpleCell, Avatar, Header, Button, Checkbox, ButtonGroup, Footer } from "@vkontakte/vkui"
import { Icon56GhostOutline } from '@vkontakte/icons';
import { Icon16DoorEnterArrowRightOutline } from '@vkontakte/icons';
import { Icon16DownloadOutline } from '@vkontakte/icons';
import { useEffect, useState } from "react"
import getWallPools from "../modules/getWallPolls";

function SettingsBody({ polls, go, activePolls, setActivePolls, setOffset, offset, loading}) {
    if (!polls) return <div className="Menu"><Spinner size="large" /></div>

    const getPool = async (e, poll) => {
        if (e.target.checked) {
            if (poll.answer_ids.length || poll.anonymous) {
                setActivePolls([...activePolls, poll])
            } else {

            }
        } else {
            setActivePolls(activePolls.filter(x => x.id != poll.id))
        }
    }

    return <div style={{ width: '100%', height: '100%'}}>
        <Footer>Вы должны проголосовать в опросе</Footer>
        {
            !polls.length ? 
            <div className="Menu" style={{ marginTop: '20px'}}>
                <Icon56GhostOutline width={64} height={64}/>
                <Footer>В первых {offset + 100} постах не найдено опросов</Footer>
            </div> :
            <>
                <Header mode="secondary">Выберите опросы</Header>
                {
                    polls.map(poll => {
                        return <SimpleCell
                            key={poll.id}
                            Component="label"
                            style={{marginBottom: '10px'}}
                            before={<Avatar size={48} src="/img/post.png" initials="ИБ" gradientColor="blue" />}
                            after={<Checkbox 
                                disabled={!poll.answer_ids.length || poll.anonymous}
                                checked={activePolls.find(x => x.id === poll.id) ? true : false}
                                onChange={(e) => getPool(e, poll)}
                            />}
                            subtitle={`Голоса: ${poll.votes}. Ответы: ${poll.answers.length}`}
                        >
                        {poll.question}
                        </SimpleCell>
                    })
                }
            </>
        }
        
        <Footer>Первые {offset + 100} постов</Footer>
        <div className="ButtonsMenu">
            <ButtonGroup style={{ margin: '10px'}}>
                <Button before={<Icon16DownloadOutline />} loading={loading} onClick={() => setOffset(offset + 100)}>Загрузить еще</Button>
                {activePolls.length ? <Button before={<Icon16DoorEnterArrowRightOutline />} data-to="home" onClick={go}>Далее</Button> : null}
            </ButtonGroup>
        </div>

    </div>
}

export default function Settings({ id, go, setActivePolls, group, activePolls}) {
    const [polls, setPools] = useState(null)
    const [offset, setOffset] = useState(0)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setActivePolls([])
    }, [])

    useEffect(() => {
        const fetchPolls = async () => {
            setLoading(true)
            let data = await getWallPools(offset, group.id)
            if (polls) {
                setPools([...polls, ...data])
            } else {
                setPools(data)
            }
            setLoading(false)
        }
        fetchPolls()
    }, [offset])

    return <Panel id={id} style={{ height: '100vh' }}>
        <PanelHeader before={<PanelHeaderBack data-to="home-menu" onClick={go}/>}>Конкурс</PanelHeader>
        <SettingsBody 
            loading={loading}
            polls={polls} 
            go={go} 
            activePolls={activePolls} 
            setActivePolls={setActivePolls} 
            offset={offset}
            setOffset={setOffset}
        />
    </Panel>
}