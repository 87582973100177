import { ModalPage, ModalRoot, ModalPageHeader, PanelHeaderButton, Footer, Group, Link, Div } from '@vkontakte/vkui'
import { Icon24Dismiss } from '@vkontakte/icons';
import { Icon24LogoVkColor } from '@vkontakte/icons';

const AboutModal = ({ setModal }) => {
    return <ModalRoot activeModal='select'>
            <ModalPage id='select' onClose={() => setModal(null)}        
                header={
                    <ModalPageHeader
                       after={
                           <PanelHeaderButton onClick={() => setModal(null)}>
                                <Icon24Dismiss/>
                           </PanelHeaderButton>
                        } >
                            О приложении
                    </ModalPageHeader>
            }>
            <Div>
                <img src='/img/banner.png' alt='img' className='BannerImg'/>
                <span className='InfoText'>
                    С помощью приложения "Проверка опросов" вы можете быстро и просто узнать сколько людей из проголосовавших в опросе состоят в сообществе, а также увидеть кто именно не вступил.<br/><br/>Изначально приложение рассчитано на администраторов, редакторов и модераторов групп. Но и любой желающий может проверить опрос вставив в приложение ссылку на запись с опросом из группы.
                </span>
                <Footer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Icon24LogoVkColor width={18} height={18}/>&nbsp; Наша группа: <Link href='https://vk.com/4vkinfo'  target='_blank'>&nbsp; vk.com/4vkinfo</Link>
                </Footer>
            </Div>
        </ModalPage>
    </ModalRoot>
}

export default AboutModal