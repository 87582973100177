import { Card, Header, Panel, PanelHeader, Spinner, PanelHeaderBack } from '@vkontakte/vkui';
import { useEffect, useRef, useState } from 'react';
import { Icon36Done } from '@vkontakte/icons';
import { utils } from '../modules/utils';
import request from '../modules/getHttp'
import CalculateResult from './CalculateResult';

const Result = ({ id, go, settings, setModal, setPopout, group}) => {
    const interval = useRef(null)
    const [users, setUsers] = useState({ result: [], success: 'started', passed: 0 })
    const [infoUsers, setInfoUsers] = useState({ result: [], success: 'pending', passed: 0})
    const [resultUsers, setResultUsers] = useState({ result: {}, success: 'pending', passed: 0})
    const [groups, setGroups] = useState({ result: [], success: 'pending', passed: 0})
    const [bonuses, setBonuses] = useState([])

    const usersProgress = useRef(0)
    const usersInfoProgress = useRef(0)
    const groupsProgress = useRef(0)

    const [restart, setRestart] = useState(null)
    const [answers, setAnswers] = useState({})

    const startUserInfo = async (usersIds) => {
        setBonuses(await request('/get_bonuses', "POST", { group_id: group.id}))
        
        if (users.result.length) return
        setInfoUsers({ result: [], success: 'started', passed: 0})

        let useersItems = []
        for (let key in usersIds) {
            if (usersIds[key]?.length) {
                useersItems.push(...usersIds[key])
            }
        }

        let usersInfo = await utils.usersGet(useersItems, interval, (i) => {
            usersInfoProgress.current = usersInfoProgress.current + i
            setRestart(Math.random() * 1000)
        })

        if (settings.groupSubscribeAll) {
            await utils.getInfo(settings, interval, usersInfo)
        }

        let cities = []
        let citiesInfo = []

        if (settings?.cities) {
            citiesInfo.push(...settings.cities)
        }

        settings?.cities?.forEach(city => cities.push(city.title.slice(0, -1).toLowerCase()))
        if (settings.fetchedRegions) {
            settings.fetchedRegions.forEach(city => {
                cities.push(city.title.slice(0, -1).toLowerCase())
                citiesInfo.push(city)
            })
        }
        
        if (settings.city && cities.length) {
            if (settings.city === 1) {
                usersInfo.map(user => {
                    if (!citiesInfo.find(x => x.id === user?.city?.id)) {
                        if (!user.ban) user.ban = []
                        user.ban.push('city')
                    }
                    if (!user?.city?.id) {
                        if (!user.ban) {
                            user.ban = []
                            user.ban.push('city')
                        }
                    }
                })
            } else {
                await utils.getCitySubscribers(usersInfo, interval, (i) => {
                    groupsProgress.current = groupsProgress.current + i
                    setRestart(Math.random() * 1000)
                })

                let groups = []
                usersInfo.map(user => {
                    if (!user.subscribers) return
                    user.subscribers.groups.items.map(group => {
                        if (!groups.find(x => x === group)) {
                            groups.push(group)
                        }
                    })
                })

                let groupResult = await utils.getCityGroups(groups, cities, interval, settings, (i) => {
                    groupsProgress.current = groupsProgress.current + i
                    setRestart(Math.random() * 1000)
                })

                setGroups({ result: groupResult, success: 'started', passed: 0})

                usersInfo.map(user => {
                    let ban = false
                    if (!user?.subscribers?.groups?.items.length) {
                        ban = true
                    } else {
                        let groups = 0

                        user?.subscribers?.groups?.items.map(group_id => {
                            let group = groupResult.find(x => x.id === group_id)
                            if (!group) return
                            let citiesString = cities.join('|').replace(/\(/g, "")

                            if (group.name.toLowerCase().match(new RegExp(citiesString)) !== null) {
                                groups ++
                            }
                        })

                        if (groups < 3) {
                            ban = true
                        }
                    }

                    if (ban) {
                        if (!user.ban) user.ban = []
                        user.ban.push('city')
                    }
                })

            setGroups({result: groupResult, success: true})
        }
        }

        if (settings.sex) {
            usersInfo.filter(x => x.sex != (settings.sexInput === "Мужчины" ? 2 : 1)).map(user => {
                if (!user.ban) user.ban = []
                user.ban.push('sex')
            })
        }

        if (settings.deleteUsers) {
            usersInfo.filter(x => x.deactivated).map(user => {
                if (!user.ban) user.ban = []
                user.ban.push('ban')
            })
        }

        if (settings.friendsCount) {
            usersInfo.filter(x => x.followers_count < +settings.friendsCountInput || !x.followers_count).map(user => {
                if (!user.ban) user.ban = []
                user.ban.push('frends')
            })
        }

        if (settings.age) {
            usersInfo.map(user => {
                let ban = false
                if (!user.bdate) {
                    ban = true
                } else if (user.bdate.split('.').length < 3) {
                    ban = true
                } else {
                    let bdate = user.bdate.split('.')
                    let date = new Date() 

                    date.setDate(+bdate[0])
                    date.setMonth(+bdate[1] - 1)
                    date.setFullYear(+bdate[2])
            
                    let date2 = new Date()
                    date2.setFullYear(date2.getFullYear() - +settings.ageInput)
                    if (date2 < date) {
                        ban = true
                    }
                }

                if (ban) {
                    if (!user.ban) user.ban = []
                    user.ban.push('age')
                }

            })
        }

        let poll = {}

        for (let key in usersIds) {
            if (usersIds[key]?.length) {
                let newUsers = []
                usersIds[key].map(userId => {
                    let user = usersInfo.find(x => x.id === userId)
                    newUsers.push(user)
                })
                poll[key] = newUsers
            }
        }

        setResultUsers({ result: poll, success: true, passed: 0})
        setInfoUsers({ result: usersInfo, success: true, passed: 0})
    }
    
    useEffect(() => {
        const start = async () => {
            let usersIds = await utils.getPool(settings.result, interval, (i) => {
                usersProgress.current = usersProgress.current + i
                setRestart(Math.random() * 1000)
            })
            
            setAnswers(usersIds.answers)
            setUsers({ result: usersIds.users, success: true, passed: users.length })
            startUserInfo(usersIds.users)
        }
        start()

        return () => {
            if (interval.current) {
                clearTimeout(interval.current)
                clearInterval(interval.current)
                interval.current = false
            }
        }
    }, [])


    return <Panel id={id}>
        <PanelHeader before={<PanelHeaderBack data-to="home" onClick={go} />}>Результат</PanelHeader>
        <div className='MainMenu'>

            {
                <div hidden={resultUsers.success === true}>
                    <Header mode="secondary">Опросы для анализа</Header>
                    {
                        settings?.result?.map((poll, index) => {
                            return <Card key={index}>
                                <div className='PostMenu' style={{ padding: '5px', marginBottom: '10px'}}>
                                    <img src="/img/post.png" alt='post'/>
                                    <div className='PostMenuBody'>
                                        <h1>{poll.question}</h1>
                                        <p>Голоса: {poll.votes}. Ответы: {poll.answers.length} </p>
                                    </div>
                                </div>
                            </Card>
                        })
                    }
                    <Card>
                        <div className='PostMenu' style={{ padding: '5px', marginBottom: '10px'}}>
                            <div style={{ marginRight: "10px", marginLeft: '10px'}}>
                                {
                                    users.success === 'started' ? 
                                        <Spinner size='medium'/>
                                    :   
                                        <Icon36Done />
                                }
                            </div>
                            <div className='PostMenuBody'>
                                <h1>Обработка пользователей</h1>
                                <p>Найдено участников: {users.success === 'started' ? usersProgress.current : users.result.count }</p>
                            </div>
                        </div>
                    </Card>

                    <Card>
                        <div className='PostMenu' style={{ padding: '5px', marginBottom: '10px'}}>
                            <div style={{ marginRight: "10px", marginLeft: '10px'}}>
                                {
                                    infoUsers.success === 'started' || infoUsers.success === 'pending' ? 
                                        <Spinner size='medium'/>
                                    :
                                        <Icon36Done />
                                }
                            </div>
                            <div className='PostMenuBody'>
                                <h1>Собираем информацию</h1>
                                <p>Обработано участников: {infoUsers.success === 'started' ? usersInfoProgress.current : infoUsers.result.length}</p>
                            </div>
                        </div>
                    </Card>

                    {
                        settings.city === 2 && settings.cityInput ? 
                        <Card>
                            <div className='PostMenu' style={{ padding: '5px', marginBottom: '10px'}}>
                                <div style={{ marginRight: "10px", marginLeft: '10px'}}>
                                    {
                                        groups.success === 'started' || groups.success === 'pending' ? 
                                            <Spinner size='medium'/>
                                        :
                                            <Icon36Done />
                                    }
                                </div>
                                <div className='PostMenuBody'>
                                    <h1>Поиск города</h1>
                                    <p>Обработано подписок: {groupsProgress.current}</p>
                                </div>
                            </div>
                        </Card>
                    : null
                    }
                        </div>
                    }

            </div>

            <CalculateResult 
                bonuses={bonuses}
                users={resultUsers.result} 
                answers={answers} 
                infoUsers={infoUsers.result} 
                setModal={setModal}
                setPopout={setPopout}
            />
    </Panel> 
}

export default Result