import { Panel, PanelHeader, Button} from '@vkontakte/vkui';
import { utils } from './modules/utils';
const GetToken = ({ id, go }) => {

    const getToken = async () => {
        try {
            let access_token = (await utils.getToken()).access_token
            window.process.access_token = access_token
            if (!access_token) return go('get_token')
            go('init')
        } catch (e) {
            console.error(e);
            return go('get_token')
        }
    }

	return <Panel id={id}>
		<PanelHeader>Авторизация</PanelHeader>
        <div className='MainMenu'>
            <div className='AuthMenu'>
                <p className='AuthMenuHeader'>Для того чтобы продолжить, вы должны разрешить доступ приложению.</p>
                <Button onClick={getToken}>Разрешить</Button>
            </div>
        </div>
	</Panel>
};

export default GetToken;