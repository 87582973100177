import { Checkbox, CustomSelectOption, Chip, Avatar, Card } from "@vkontakte/vkui";
import { ChipsSelect } from "@vkontakte/vkui/dist/components/ChipsSelect/ChipsSelect";
import { useEffect, useState } from 'react';
import { utils } from '../modules/utils';

export default function SubSettings({ settings, setSettings }) {
    const [selectedGroups, setSelectedGroups] = useState([])
	const [groups, setGroups] = useState([])
	const [debounce, setDebounce] = useState(0)
	const [fetching, setFetching] = useState(false)

    useEffect(() => {
        setSettings({...settings, groups: selectedGroups})
    }, [selectedGroups])


    const groupsChipsProps = {
        value: selectedGroups,
        onChange: setSelectedGroups,
        options: groups,
        placeholder: 'Не выбраны',
        emptyText: 'Совсем ничего не найдено',
    };

    const searchGroups = (q) => {
		setFetching(true)
		if (debounce) clearTimeout(debounce)

		let timeout = setTimeout(async () => {
			let _groups = await utils.getGropus(q)

			_groups.items.map(x => {
				x.value = x.id
				x.src = x.photo_200
				x.label = x.name
			})

			setGroups(_groups.items);
			setFetching(false)
		}, 350)

		setDebounce(timeout)
	}

    return  <Card style={{ overflow: 'hidden', marginTop: '10px' }}>
        <Checkbox 
				onChange={(e) => setSettings({...settings, groupSubscribeAll: e.target.checked})}
				description="Проверять на вступление в выбранную группу"
			>
				Проверка подписки
		</Checkbox>

        {
            settings.groupSubscribeAll ? 
                <ChipsSelect
                style={{ margin: '10px'}}
                    id="groups"
                    fetching={fetching}
                    {...groupsChipsProps}
                    showSelected={false}
                    renderChip={({ value, label, option: { src }, ...rest }) => (
                        <Chip value={value} before={<Avatar size={20} src={src} />} {...rest}>
                            {label}
                        </Chip>
                    )}
                    onInputChange={(e) => {
                        searchGroups(e.target.value)
                    }}
                    renderOption={({ option: { src, icon }, ...otherProps }) => {
                        return (
                          <CustomSelectOption
                            before={icon ? <Avatar size={20}>{icon}</Avatar> : <Avatar size={20} src={src} />}
                            {...otherProps}
                          />
                        );
                    }}
                />
            : null
        }

    </Card>
}