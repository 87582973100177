import { Snackbar, Avatar } from "@vkontakte/vkui"
import { Icon16Done } from '@vkontakte/icons';
import { Icon20ErrorCircleOutline } from '@vkontakte/icons';
import { Icon20WarningTriangleOutline } from '@vkontakte/icons';

const SnackbarItem = ({code, text, setSnackbar, top, style}) => {
    if (code === 'error') {
        return (
            <Snackbar
                style={{ top: top ? top : 'auto', ...style}}
                onClose={() => setSnackbar(null)}
                before={
                    <Avatar
                        size={24}
                        style={{ background: "var(--negative-color)" }}
                    >
                        <Icon20ErrorCircleOutline fill="#fff" width={14} height={14} />
                    </Avatar>
                }>{text} </Snackbar>
        )

    } else if (code === 'warn') {
        return (
            <Snackbar
                style={{ top: top ? top : 'auto', ...style}}
                onClose={() => setSnackbar(null)}
                before={
                    <Avatar
                        size={24}
                        style={{ background: "var(--warn-color)" }}
                    >
                    <Icon20WarningTriangleOutline fill="#fff" width={14} height={14} />
                </Avatar>
            }>{text} </Snackbar>)
    } else {
        return(
            <Snackbar
                style={{ top: top ? top : 'auto', ...style}}
                onClose={() => setSnackbar(null)}
                before={
                    <Avatar
                        size={24}
                        style={{ background: "var(--positive-color)" }}
                    >
                        <Icon16Done fill="#fff" width={14} height={14} />
                    </Avatar>
                }>{text} 
            </Snackbar>)
    }
}


export default SnackbarItem