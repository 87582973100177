import { Icon56CompassCircleFillPurple } from '@vkontakte/icons';
import { Group, Header, SimpleCell, FormItem, Progress, Footer, Tappable, Avatar, Button, Alert, Checkbox, Select, Input} from '@vkontakte/vkui';
import { useEffect, useState } from 'react';
import ResultModal from './ResultModal';
import { Icon201CircleFillGold } from '@vkontakte/icons';
import { Icon202CircleFillSilver } from '@vkontakte/icons';
import { Icon203CircleFillBronze } from '@vkontakte/icons';
import { Icon28Crown } from '@vkontakte/icons';
import { Icon12Switch } from '@vkontakte/icons';
import { Icon16DownloadOutline } from '@vkontakte/icons';
import { Icon16CrownCircleFillVkDating } from '@vkontakte/icons';
import { utils } from '../modules/utils';

const winnersItems = {
    "1": <Icon201CircleFillGold/>,
    "2": <Icon202CircleFillSilver/>,
    "3": <Icon203CircleFillBronze/>
}


const GetWinnnersAlert = ({getWinners, setPopout, infoUsers, answers}) => {
    const [answer, setAnswer] = useState([])
    const [settings, setSettings] = useState({ length: 1 })

    useEffect(() => {
        let arrAnswers = [{id: 0, text: "Все ответы"}]
        for (let key in answers) {
            arrAnswers.push({id: key, text: answers[key]})
        }
        setAnswer(arrAnswers)
    }, [])

    return <Alert actions={[
        {
          title: 'Рассчитать',
          mode: 'destructive',
          autoClose: true,
          action: () => getWinners(settings),
        },
        {
          title: 'Отмена',
          autoClose: true,
          mode: 'cancel',
        },
      ]}
      actionsLayout="horizontal"
      onClose={() => setPopout(null)}
      header="Выбор победителей"
      text="Дополнительные настройки"
      >
        <Checkbox 
            defaultChecked={settings?.type}
            onChange={(e) => {
                setSettings({...settings, type: e.target.checked})
            }}
            description="Выбор победителя среди тех кто прошел по критериям"
        >
            Учитывать критерии
        </Checkbox>

        <Header mode='secondary'>Учитывать голоса за:</Header>
        <Select 
            placeholder={`Все ответы`}
            onChange={(e) => {
                setSettings({...settings, answer: e.target.value})
            }}
            options={answer.map(ans => ({
                label: ans.text,
                value: +ans.id
            }))}
        />

        <Header mode='secondary'>Количество победителей:</Header>
        <Input 
            value={settings.length || ''}
            type='number'
            min={0}
            onChange={(e) => {
                if (+e.target.value <= 10 && +e.target.value < infoUsers.length) {
                    setSettings({...settings, length: Number(+e.target.value * 1)})
                }
            }}
        />
    </Alert>
}

const CalculateResult = ({users, answers, infoUsers, setModal, setPopout, bonuses}) => {
    const [body, setBody] = useState([])
    const [winners, setWinners] = useState([])

    function shuffle(array) {
        array.sort(() => Math.random() - 0.5);
    }

    const dowloadResult = () => {
        let text = 'Не прошли проверку:\n\n'
        for (let key in users) {
            users[key].filter(x => x?.ban?.length).map(user => {
                text += `vk.com/id${user.id}\n`
            })
        }

        text += '\n\nПрошли проверку:\n\n'
        for (let key in users) {
            users[key].filter(x => !x?.ban?.length).map(user => {
                text += `vk.com/id${user.id}\n`
            })
        }

        utils.download('Результат.txt', text)
    }
      
    const getWinners = (settings) => {
        let candidateUsers = infoUsers

        if (settings.answer) {
            candidateUsers = users[settings.answer]
        }

        if (settings.type) {
            candidateUsers = candidateUsers.filter(x => !x.ban)
        }


        let win = []
        shuffle(candidateUsers)
        for (let i = 0; i < settings.length; i++) {
            win.push({...candidateUsers[i], answer: settings.answer ?? null})
        }

        if (!settings.answer) {
            win.map(user => {
                for (let key in users) {
                    let us = users[key].find(x => x.id === user.id)
                    if (us) {
                        user.answer = key
                        return
                    }
                }
            })
        }

        setWinners(win)
    }


    useEffect(() => {
        let post = []
        const usersArray = []
        for (let key in users) {
            usersArray.push({ key, users: users[key]})
        }

        let allPoints = 0

        if (usersArray.length) {
            usersArray.forEach(item => {
                let bonus = bonuses.find(x => x.answer.id === +item.key)
                item.points = item.users.filter(x => !x?.ban).length 
                if (bonus) {
                    item.points += bonus.points
                }
                allPoints += item.points
            })

            usersArray.sort((a, b) => b.points- a.points).map(item => {
                post.push(
                    <Tappable key={item.key} onClick={() => setModal(<ResultModal answer={item} setModal={setModal}/>)}>
                        <div >
                            <div className="ResultPollAnswer">
                                <FormItem 
                                    id="progresslabel" 
                                    top={answers[item.key]} 
                                    bottom={`Проголосовали ${item.users.length} из них ${item.users.filter(x => x?.ban?.length).length} не засчитано. ${(item.points - item.users.filter(x => !x?.ban?.length).length) ? `Бонусные голоса: ${(item.points - item.users.filter(x => !x?.ban).length)} ` : ''}Итог: ${item.points ?? 0}`}
                                >
                                    <Progress aria-labelledby="progresslabel" value={Math.round(item.points / allPoints * 100)} />
                                 </FormItem>
                            </div>
                        </div>
                    </Tappable>
                )
            })

            setBody(post)
        }
    }, [users, infoUsers])

    if (!body.length) return <div className='CalculateResultLoading'>
        <div><Icon56CompassCircleFillPurple /></div>
        <p>Идет подсчет!</p>
    </div>

    return <div>
        <Group header={<Header mode="secondary">Результат подсчетов</Header>}>
            <div>
                <Footer>Всего проголосовало {infoUsers.length} человек из них прошли проверку {infoUsers.filter(x => !x.ban).length}</Footer>
            </div>
            {body}
            {
                winners.length ?
                <div className="MainMenu">
                <div className='Separator'></div>
                <div className='UsersHeader'>
                    <div className='UsersHeaderCrown'>
                        <Icon28Crown width={58} height={58}/>
                    </div>
                    <h1>Победители конкурса</h1>
                </div>
                <div className='UsersHeaderBody'>
                {
                    winners.map((user, index) => {
                        if (!user.id) return null
                        return <a key={user.id} href={`https://vk.com/id${user?.id}`} target='_blank'>
                            <SimpleCell
                                before={<Avatar size={48} src={user?.photo_200} initials="ИБ" gradientColor="blue" />}
                                after={winnersItems[index + 1] ?? <div className='number'>{index + 1}</div>}
                            >
                            <div className='HeaderTextBottom'>{`${user?.first_name} ${user?.last_name}`}</div>
                        </SimpleCell>
                        </a>
                    })
                }
                </div>

                <div className='UsersFooter'>
                    <p>Победители определены: {(new Date()).toLocaleString()}</p>
                </div>

                <div className='ButtonsMenu' style={{ marginTop: '10px', flexDirection: 'column'}}>
                    <Button 
                        before={<Icon12Switch />}
                        onClick={() => {
                            setPopout(<GetWinnnersAlert 
                                setPopout={setPopout}
                                infoUsers={infoUsers}
                                getWinners={getWinners}
                                answers={answers}
                            />)
                        }}
                    >
                        Рассчитать еще раз
                    </Button>
                    <Button before={<Icon16DownloadOutline />} onClick={dowloadResult} style={{ margin: '10px'}}>Скачать результат</Button>
                </div>
                </div>
                : 
                <div className='ButtonsMenu' style={{ margin: '10px', flexDirection: 'column' }}>
                    <Button before={<Icon16CrownCircleFillVkDating />} onClick={() => {
                        setPopout(<GetWinnnersAlert 
                            setPopout={setPopout}
                            infoUsers={infoUsers}
                            getWinners={getWinners}
                            answers={answers}
                        />)
                    }}>
                    Провести конкурс
                    </Button>

                    <Button before={<Icon16DownloadOutline />} onClick={dowloadResult} style={{ margin: '10px'}}>Скачать результат</Button>
                    <Footer>Определить победителя из проголосовавших</Footer>
                </div>
            }
        </Group>
    </div>
}

export default CalculateResult